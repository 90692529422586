import React, {useEffect, useRef, useState} from 'react';
import "./Create.css";

import {loadStripe} from '@stripe/stripe-js';
import { useLongPress } from 'use-long-press';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Snapshot from "../../../Models/Snapshot";
import Floorplan from "../../../Models/Floorplan";
import MapItButton from '../../Common/MapItButton/MapItButton';
import * as Security from '../../Security/Security';

import uploadIcon from '../../../Assets/MapIt-Upload-Icon.png';
import * as Loader from 'react-spinners';

import popupmedia from '../../../Assets/popup_media2.png'

import PageTitle from '../../Common/PageTitle/PageTitle';

const Create = ({fpBase64, 
  setFpBase64, 
  ssObjs, 
  setSSObjs, 
  fpWidth, 
  setFpWidth, 
  fpHeight,
  setFpHeight, 
  file, 
  setFile, 
  setPreview, 
  fpX, 
  setFpx, 
  fpY, 
  setFpy, 
  fpType, 
  setFpType, 
  snapSize, 
  setSnapSize,
  fpOgWidth,
  setFpOgWidth,
  setFpOgHeight,
  fpOgHeight}) => {
  const [loading, setLoading] = useState(false);
  const [selectedSS, setSelectedSS] = useState(0);
  const [longPress, setLongPress] = useState(false);
  const [ssDelActi, setSSDelActi] = useState(false);
  const [ssIdx, setSSIdx] = useState(-1);
  const [ssViewActive, setSSViewActive] = useState(false);
  const [sharePressed, setSharePressed] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [ssDoubleClicked, setSSDoubleClicked] = useState(0);
  const [email, setEmail] = useState("");
  const [isFpPdf, setIsFpPdf] = useState(false);

  const popupRef = useRef();
  const fpRef = useRef();
  const btnRef = useRef();

  const prod_host = "https://server.mapit.cc";

  // const stripeProductKey = "pk_test_51P7G7WAAPuhdwRL35ymriOpqNPcTQy2S8cW7jxv4dVY1lgHBnatmUk3a9RgvQcQ5Z5xIbzB8Lf6VajWl6NGNE5ZQ00QQG5q47y";
  const stripeProductKey = "pk_live_51P7G7WAAPuhdwRL3ItOHsgW1Ds2UmF7bYPmECSg1tg125aqxvrweluh30wcmAfUaoHQ2HsefcdkndXb1oYBTeKXF00qmntGvir";

  useEffect(() => {
    const closePopup = (e) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        setSharePressed(false);
      }
      // if (!sharePressed 
      //   && selectedSS !==0
      //   && fpRef.current 
      //   && !fpRef.current.contains(e.target) 
      //   && btnRef.current 
      //   && !btnRef.current.contains(e.target)) {
      //     setSelectedSS(0);
      //   }
    }
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
      // let width = fpWidth*windowWidth/0.8;
      // let height = fpHeight*windowHeight/0.8;
      // const dimObj = getFrameDim (height, width, window.innerHeight, window.innerWidth);
      // let fpx = ((window.innerWidth - 0.8*dimObj.width)/2)/window.innerWidth;
      // let fpy = ((window.innerHeight - 0.8*dimObj.height)/2)/window.innerHeight;
      // setFpWidth(0.8*dimObj.width/window.innerWidth);
      // setFpHeight(0.8*dimObj.height/window.innerHeight);
      // setFpx(fpx);
      // setFpy(fpy);
    };
    document.body.addEventListener('mousedown', closePopup);
    window.addEventListener('resize', handleResize);
    return () => {
      document.body.removeEventListener('mousedown', closePopup);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  async function getImageDimensions(file) {
    let img = new Image();
    img.src = URL.createObjectURL(file);
    await img.decode();
    let width = img.width;
    let height = img.height;
    return {
        width,
        height,
    }
  }

  async function getImageDimBase64 (base64) {
    let img = new Image();
    img.src = base64;
    await img.decode();
    let width = img.width;
    let height = img.height;
    return {
        width,
        height,
    }
  }

 function convertPdfToJpg (base64) {
  return new Promise ((resolve,reject) => {
    fetch(`${prod_host}/pdfToJpg`, {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
          b64: base64,
      })
    })
    .then(response => response.json())
    .then(serverResponse => {
      resolve(serverResponse);
    })
    .catch ((err) => {
      reject(err);
    });
  });
}

function isUnderFileLimit (size) {
  const oneMB = 1048576;
  const oneKB = 1024;
  if (size < 10*oneMB) {
    return true;
  } else {
    return false;
  }
}

  async function handleChange(event) {
    console.log(event.target.files[0]);
    setLoading(true);
    let base64Str = "";
    console.log("File Type: ");
    const base64 = await convertBase64(event.target.files[0]);
    console.log(base64.substring(0,100));
    if (isUnderFileLimit(event.target.files[0].size)) {
      if (event.target.files[0].type === "application/pdf") {
        base64Str = base64.split("data:application/pdf;base64,")[1];
        const pdfToImgData = await convertPdfToJpg(base64Str);
        const {width, height} = await getImageDimBase64("data:image/jpeg;base64," + pdfToImgData);
        setFpOgWidth(width);
        setFpOgHeight(height);
        const dimObj = getFrameDim (height, width, window.innerHeight, window.innerWidth);
        setFpBase64(pdfToImgData);
        setFpType("jpeg");
        let fpx = ((windowWidth - 0.8*dimObj.width)/2)/windowWidth;
        let fpy = ((windowHeight - 0.8*dimObj.height)/2)/windowHeight;
        setFpWidth(0.8*dimObj.width/windowWidth);
        setFpHeight(0.8*dimObj.height/windowHeight);
        setFpx(fpx);
        setFpy(fpy);
        setFile(URL.createObjectURL(event.target.files[0]));
        setLoading(false);
      } else {
        // const fileType = getFileType()
        let base64Token = "";
        if (event.target.files[0].type === "image/png") {
          base64Token = "data:image/png;base64,";
          setFpType("png");
        } else if (event.target.files[0].type === "image/jpg" || event.target.files[0].type === "image/jpeg") {
          base64Token = "data:image/jpeg;base64,";
          setFpType("jpeg");
        } else {
          console.log("file type not supported");
        }
        base64Str = base64.split(base64Token)[1];
        const {width, height } = await getImageDimensions(event.target.files[0]);
        setFpOgWidth(width);
        setFpOgHeight(height);
        const dimObj = getFrameDim (height, width, window.innerHeight, window.innerWidth);
        let fpx = ((windowWidth - 0.8*dimObj.width)/2)/windowWidth;
        let fpy = ((windowHeight - 0.8*dimObj.height)/2)/windowHeight;
        setFpBase64(base64Str);
        setFile(URL.createObjectURL(event.target.files[0]));  
        setFpWidth(0.8*dimObj.width/windowWidth);
        setFpHeight(0.8*dimObj.height/windowHeight);
        setFpx(fpx);
        setFpy(fpy);
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.error("Invalid File Size", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })
    }
  }

  async function handleMultipleChange(event) {
    let ssObjList = {};
    let ssId = ssIdx;
    const loopSize = 40 - Object.keys(ssObjs).length;
    setLoading(true);
    for (let idx = 0; idx < Math.min(event.target.files.length, loopSize); idx++) {
      let file = event.target.files[idx];
      if (isUnderFileLimit(file.size)) {
        const base64 = await convertBase64(file);
        let fileType = "";
        let base64Token = "";
        if (event.target.files[0].type === "image/png") {
          base64Token = "data:image/png;base64,";
          fileType = "png";
        } else if (event.target.files[0].type === "image/jpg" || event.target.files[0].type === "image/jpeg") {
          base64Token = "data:image/jpeg;base64,";
          fileType = "jpeg";
        } else {
          console.log("file type not supported");
        }
        let base64Str = base64.split(base64Token)[1];
        const {width, height } = await getImageDimensions(event.target.files[0]);
        const dimObj = getFrameDim (height, width, windowHeight, windowWidth);
        let urlObj = URL.createObjectURL(file);
        let ssObj = new Snapshot(ssId, base64Str, 0.0, 0.0, "", urlObj, "", width, height, fileType);
        ssObj.renderWidth = 0.8*dimObj.width/windowWidth;
        ssObj.renderHeight = 0.8*dimObj.height/windowHeight;
        ssObjList[ssId] = ssObj;
        ssId = ssId - 1;
      } else {
        toast.error("Invalid File Size", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      }
    }
    setLoading(false);
    setSSIdx(ssId - 1);
    setSSObjs({...ssObjs, ...ssObjList});
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    });
  }

  const imageClick = (ssObj, index) => {
    if (longPress) {
      console.log(`long press for ssid: ${index}`);
      // render snapshot delete view
      setSSDelActi(true);
      setLongPress(false);
    }
    if (index === selectedSS) {
      setSelectedSS(0)
    } else {
      setSelectedSS(index);
    }
  }

  const bind = useLongPress(() => {
      setLongPress(true);
  });

  const fpClick = (event) => {
    if (event.type === "contextmenu") {
      setSelectedSS(0);
    }
    else if (selectedSS != 0) {
      console.log(`pagex: ${event.pageX}`);
      console.log(`pagey: ${event.pageY}`);
      console.log(`fpY height: ${fpY*windowHeight}`);
      console.log(`fpX width: ${fpX*windowWidth}`);
      console.log(`fpWidth: ${fpWidth*windowWidth}`);
      console.log(`fpHeight: ${fpHeight*windowHeight}`);

      // setSSObjs[selectedSS].coordinatex = event.clientX;
      // setSSObjs[selectedSS].coordinatey = event.clientY;
      console.log(`coordx: ${(event.pageX-(fpX*windowWidth))/(fpWidth*windowWidth)}`);
      console.log(`coordy: ${(event.pageY-(fpY*windowHeight))/(fpHeight*windowHeight)}`);
      ssObjs[selectedSS].coordinatex = (event.pageX-(fpX*windowWidth)-(getSSWidth()/2))/(fpWidth*windowWidth);
      ssObjs[selectedSS].coordinatey = (event.pageY-(fpY*windowHeight)-(getSSHeight()/2))/(fpHeight*windowHeight);
      setSSObjs({...ssObjs});
    }
    // from the event object we can get the coordiante of the click
    // use the coordinates to set the coordinates on the selcted image
  }

  const getFrameDim = (height, width, maxHeight, maxWidth) => {
    // console.log("In getFrameDim");
    // console.log(maxWidth);
    // console.log(maxHeight);
    // console.log(width);
    // console.log(height);
    if (!width) width = maxWidth;
    if (!height) height = maxHeight;
    if (width > maxWidth && height > maxHeight) {
    //   console.log(width);
    //   console.log(height);
      let wDiff = (width - maxWidth)/width;
      let hDiff = (height - maxHeight)/height;
      if (wDiff >= hDiff) {
        height = (1-wDiff)*height;
        width = (1-wDiff)*width;
      } else {
        height = (1-hDiff)*height;
        width = (1-hDiff)*width;
      }
    } else if (width > maxWidth) {
      let wDiff = (width - maxWidth)/width;
      width = (1-wDiff)*width;
      height = (1-wDiff)*height;
    } else if (height > maxHeight) {
      let hDiff = (height - maxHeight)/height;
      height = (1-hDiff)*height;
      width = (1-hDiff)*width;
    } else {
      // width and height must be less than the screen width and height
      let wDiff = (maxWidth - width)/width;
      let hDiff = (maxHeight - height)/height;
      if (wDiff >= hDiff) {
        height = (1+hDiff)*height;
        width = (1+hDiff)*width;
      } else {
        height = (1+wDiff)*height;
        width = (1+wDiff)*width;
      }
    }
    // console.log(`Debug Log: new width: ${width}`)
    // console.log(`Debug Log: new height: ${height}`)
    return {width: width, height: height}
  }

  const leftOrRight = (ssId) => {
    if (((fpX*windowWidth) + (ssObjs[ssId].coordinatex*fpWidth*windowWidth) + ssObjs[ssId].renderWidth*windowWidth*0.4)
    > (fpX*windowWidth+(fpWidth*windowWidth))) {
      return -1*ssObjs[ssId].renderWidth*windowWidth*0.4+(fpWidth*windowWidth*snapSize); // magic number 0.4? - hard type this
    } else {
      return 0;
    }
  }

  const topOrBottom = (ssId) => {
    if (((fpY*windowHeight) + (ssObjs[ssId].coordinatey*fpHeight*windowHeight) + ssObjs[ssId].renderHeight*windowHeight*0.4)
    > (fpY*windowHeight+(fpHeight*windowHeight))) {
      return -1*ssObjs[ssId].renderHeight*windowHeight*0.4+(fpWidth*windowWidth*snapSize);
    } else {
      return 0;
    }
  }

  const getSnapshotStyle = (ssId) => {
    if (ssObjs[ssId].coordinatex !== 0 || ssObjs[ssId].coordinatey !== 0) {
      console.log(`left: ${(fpX*windowWidth) + (ssObjs[ssId].coordinatex*fpWidth*windowWidth)}`);
      console.log(`top: ${(fpY*windowHeight) + (ssObjs[ssId].coordinatey*fpHeight*windowHeight)}`);
      return {position: 'absolute', 
      left: ssId!==ssDoubleClicked?(fpX*windowWidth) + (ssObjs[ssId].coordinatex*fpWidth*windowWidth)
      :((fpX*windowWidth) + (ssObjs[ssId].coordinatex*fpWidth*windowWidth) + leftOrRight(ssId)), 
      top: ssId!==ssDoubleClicked?(fpY*windowHeight) + (ssObjs[ssId].coordinatey*fpHeight*windowHeight)
      :((fpY*windowHeight) + (ssObjs[ssId].coordinatey*fpHeight*windowHeight) + topOrBottom(ssId))
      };
    } else if (ssId === ssDoubleClicked) {
      console.log("double clicked");
      const ssWidth = ssObjs[ssId].renderWidth*windowWidth*0.4;
      return {position: 'absolute', top: "50vh", left: "3.3vw"};
    } else {
      return {};
    }
  }

  const onDelSS = () => {
    console.log("snapshot del pressed");
    delete ssObjs[selectedSS];
    setSelectedSS(0);
    setSSViewActive(false);
    setSSObjs(JSON.parse(JSON.stringify(ssObjs))); // find  a better way to do this
  }

  const onCanSS = () => {
    console.log("snapshot cancel pressed");
    setSelectedSS(0);
    setSSViewActive(false);
    setSSDoubleClicked(0);
  }

  const onViewSS = () => {
    console.log("snapshot being viewed");
    setSSViewActive(true);
  }

  const onBackSS = () => {
    setSSViewActive(false);
  }

  const onSharePressed = () => {
    setSharePressed(true);
  }

  const onPreviewLink = () => {

  }

 const removeNotUsedSS = () => {
    let keyDeleted = false;
    for (let key in ssObjs) {
      let ssObj = ssObjs[key];
      if (ssObj.coordinatex === 0 && ssObj.coordinatey === 0) {
        // snapshot was not positioned therefore remove from fp
        delete ssObjs[key];
        keyDeleted = true;
      }
    }
    // if (keyDeleted) {
    //   setSSObjs(JSON.parse(JSON.stringify(ssObjs)));
    // }
  }

  const onPayment = async () => {
    setLoading(true);
    if(!Security.valEmailInput(email)) {
      onNotify();
      setLoading(false);
      return;
    }
    const stripe = await loadStripe(stripeProductKey);
    let fpObj = new Floorplan (-1, fpBase64, "", "", "", "", fpOgWidth, fpOgHeight, false, snapSize);
    // remove any snapshots that have not been positioned on the floor plan
    removeNotUsedSS();
    fpObj.snapshots = ssObjs;

    fetch(`${prod_host}/create-checkout-session`, {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        email: email,
        product_type: "p",
        fpObj: fpObj
      })
    })
    .then(response => response.json())
    .then(session => {
      console.log(session);
      if(session && session.id) {
        const result = stripe.redirectToCheckout ({
          sessionId: session.id
        });
      }
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
    });
  }

  const getSSWidth = (ssId) => {
    let ssWidth;
    if (ssId === ssDoubleClicked) {
      ssWidth = ssObjs[ssId].renderWidth*windowWidth*0.4;
    } else {
      ssWidth = fpWidth*windowWidth*snapSize;
    }
    return ssWidth;
  }

  const getSSHeight = (ssId) => {
    let ssHeight;
    if (ssId === ssDoubleClicked) {
      ssHeight = ssObjs[ssId].renderHeight*windowHeight*0.4;
    } else {
      ssHeight = fpWidth*windowWidth*snapSize; // using width here is correct to make square icons
    }
    return ssHeight;
  }

  const handleRightClick = (event) => {
    if (event.type === 'contextmenu') {
      setSelectedSS(0);
    }
  }

  const handleDragLeave = event => {
    event.stopPropagation();
    event.preventDefault();
    // Bring the endzone back to normal, maybe?
  };
  const handleDragOver = event => {
    event.stopPropagation();
    event.preventDefault();
    // Turn the endzone red, perhaps?
  };
  const handleDragEnter = event => {
    event.stopPropagation();
    event.preventDefault();
    // Play a little sound, possibly?
  };
  const handleDrop = event => {
    event.stopPropagation();
    event.preventDefault();
    // ssObjs[selectedSS].coordinatex = (event.pageX-(fpX*windowWidth)-(getSSWidth()/2))/(fpWidth*windowWidth);
    // ssObjs[selectedSS].coordinatey = (event.pageY-(fpY*windowHeight)-(getSSHeight()/2))/(fpHeight*windowHeight);
    // setSSObjs({...ssObjs});
  };

  const imageDragEnd = (event, ssId) => {
    imageDrag(event, ssId);
  }

  const imageDragStart = (event, ssId) => {
    imageDrag(event, ssId);
  }

  const imageDrag = (event, ssId) => {
    console.log(`coordx: ${(event.pageX-(fpX*windowWidth))/(fpWidth*windowWidth)}`);
    console.log(`coordy: ${(event.pageY-(fpY*windowHeight))/(fpHeight*windowHeight)}`);
    ssObjs[ssId].coordinatex = (event.pageX-(fpX*windowWidth)-(getSSWidth()/2))/(fpWidth*windowWidth);
    ssObjs[ssId].coordinatey = (event.pageY-(fpY*windowHeight)-(getSSHeight()/2))/(fpHeight*windowHeight);
    setSSObjs({...ssObjs});
  }

  const onDoubleClick = (ssId) => {
    setSSDoubleClicked(ssId);
  }

  const onLargeImageClick = () => {
    setSSDoubleClicked(false);
  }

  const onEmailChange = (email) => {
    setEmail(email.target.value);
    console.log(email.target.value);

  }

  const onSmallPressed = () => {
    setSnapSize(0.025);
  }

  const onMedPressed = () => {
    setSnapSize(0.0375);

  }

  const onLargePressed = () => {
    setSnapSize(0.05);
  }

  const onNotify = () => {
    toast.error("Email Invalid", {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })
  }

  const getFpImageSrc = (base64, file) => {
    let imgSrc = "";
    if (base64) {
      switch (fpType) {
        case "png":
          imgSrc = "data:image/png;base64," + base64;
          break;
        case "jpeg":
          imgSrc = "data:image/jpeg;base64," + base64;
          break;
        default:
          break;
      }
      return imgSrc;
    } else {
      return "https://d1se9yqadop5at.cloudfront.net/" + file;
    }
  }

  const getImageSrc = (ssObj) => {
    if (ssObj.image) {
      // console.log(`ss base64 path: ${ssObj.snapshot_id}`)
      let base64Token = "";
      switch(ssObj.fileType) {
        case "jpeg":
          base64Token = "data:image/jpeg;base64,";
          break;
        case "png":
          base64Token = "data:image/png;base64,";
          break;
      }
      return base64Token + ssObj.image;
    } else {
      // console.log(`ss remote path: ${ssObj.remote_file_path}`)
      return "https://d1se9yqadop5at.cloudfront.net/" + ssObj.remote_file_path
    }
  }

  return (
    <div className="create">
      <PageTitle title="Upload Pictures and Floor Plans" />
      { !file &&
      <div className="choose-file">
        <form>
          <input type="file" accept="image/jpeg,image/png,application/pdf" id="actual-btn" onChange={handleChange} hidden/>
          <label for="actual-btn">
            <img 
            style={{height: 53, width: 169, cursor: 'pointer'}}
            alt="upload floor plan"
            src={uploadIcon}/>
          </label>
        </form>
      </div>
      }
      { file && 
      <div className="edit-outter">
        <div className="snapshots">
          { !ssViewActive &&
         <div className="ss-inner">
         <div className="ss-contain">
           {Object.keys(ssObjs).map((ssId, index) => (
             ssId !== ssDoubleClicked && !ssObjs[ssId].deleted
             ?
             <div className="ss-img">
               <img 
               key={index} 
               src={getImageSrc(ssObjs[ssId])}
               alt="interior home picture"
               style={{
                 ...{border: ssId!==selectedSS?"1.5px solid white":"3px solid #FCC81A", 
                 borderRadius: "5px", 
                 cursor: "pointer", 
                 width: getSSWidth(ssId), 
                 height: getSSHeight(ssId)}, 
                 ...getSnapshotStyle(ssId)}} 
               onClick={() => imageClick(ssObjs[ssId], ssId)}
               onDragEnd={(event) => imageDragEnd(event, ssId)}
              //  onDrag={(event) => imageDrag(event, ssId)}
               onDragStart={(event) => imageDragStart(event, ssId)}
               onDoubleClick={(event) => onDoubleClick(ssId)}/>
             </div>
             :
             ( !ssObjs[ssId].deleted 
             ?
             <div className="ss-img">
               <img
               key={index} 
               src={getImageSrc(ssObjs[ssId])} 
               style={{
                 ...{border: "1.5px solid white", 
                 zIndex:1, 
                 borderRadius: "5px", 
                 cursor: "pointer", 
                 width: getSSWidth(ssId), 
                 height: getSSHeight(ssId)}, 
                 ...getSnapshotStyle(ssId)}} 
               onClick={() => onLargeImageClick()}
               alt="interior home picture" /> 
             </div>
             :
             <div className='ss-img'></div>
             )            
           ))}
         </div>
         <div className="size-btn-div">
           <div className="size-btn-div-inner">
             <MapItButton btnRef={btnRef} onSubmit={onSmallPressed} text="Small" className="sizebtn"/>
           </div>
           <div className="size-btn-div-inner">
             <MapItButton btnRef={btnRef} onSubmit={onMedPressed} text="Medium" className="sizebtn"/>
           </div>
           <div className="size-btn-div-inner">
             <MapItButton btnRef={btnRef} onSubmit={onLargePressed} text="Large" className="sizebtn"/>
           </div>
         </div>
       </div>
        }
        </div>
        <div ref={fpRef} className="floorplan"           
          onDragOver={(event) => handleDragOver(event)}
          onDragEnter={(event) => handleDragEnter(event)}
          onDragLeave={(event) => handleDragLeave(event)}
          onDrop={(event) => handleDrop(event)}>
          { !ssViewActive
          ? <img src={getFpImageSrc(fpBase64, "")} 
          width={fpWidth*windowWidth} 
          height={fpHeight*windowHeight} 
          onClick={(event) => fpClick(event)} 
          onContextMenu={(event) => fpClick(event)}
          alt="listing floor plan"/>
          : <img src={ssObjs[selectedSS].local_file_path} 
          width={ssObjs[selectedSS].width*windowWidth} 
          height={ssObjs[selectedSS].height*windowHeight}
          alt="listing floor plan"/>
          }
          <Loader.DotLoader
          color="#FCC81A"
          loading={loading}
          cssOverride={{position: "absolute", borderColor: "#FCC81A", zIndex: 1}}
          size={48}
          aria-label="Loading Spinner"
          data-testid="loader"
          />
        </div>
        <div className="edit-btns">
          <div className="edit-btn">
            <form>
              <label className="label-btn" for="edit-fp">Change Floor Plan</label>
              <input ref={btnRef} type="file" id="edit-fp" hidden onChange={handleChange}/>
            </form>
          </div>
          <div className="snap-btn">
            <form>
              <label className="label-btn" for="ss-btn">Upload Property Photos</label>
              <input ref={btnRef} type="file" accept="image/png,image/jpg,image/jpeg" id="ss-btn" hidden multiple onChange={handleMultipleChange}/>
            </form>
          </div>
          <div className="snap-menu">
            { selectedSS !== 0 && 
              <>
              {/* {ssViewActive &&
                <div className="view-btn">
                  <MapItButton onSubmit={onBackSS} text="Back" className="viewbtn"/>
                </div>
              }
              {!ssViewActive &&
                <div className="view-btn">
                  <MapItButton btnRef={btnRef} onSubmit={onViewSS} text="View" className="viewbtn"/>
                </div>
              }
              {!ssViewActive &&
                <div className="view-btn">
                  <MapItButton btnRef={btnRef} onSubmit={onCanSS} text="Unselect" className="viewbtn"/>
                </div>
              } */}
              <div  className="view-btn">
                <MapItButton btnRef={btnRef} onSubmit={onDelSS} text="Delete" className="viewbtn"/>
              </div>
              </>
            }
          </div>
          <div className="share-btn">
            <MapItButton btnRef={btnRef} onSubmit={onSharePressed} text="Share" className="sharebtn"/>
          </div>
        </div>
      </div>
      }
      { sharePressed && 
        <div ref={popupRef} className="share-popup-outter">
          <div className="share-popup-inner">
            <div className="share-popup-img">
              <img
              className="popup-image" 
              src={popupmedia}
              width={0.2*windowWidth}
              height={0.5*windowHeight}
              alt="Home virtual tour"/>
              {/* <Link className="url-link-style" to="/preview" spy={true} smooth={true}>
                <MapItButton onSubmit={() => setPreview(true)} text="URL Preview" className="urlbtn"/>
              </Link> */}
            </div>
            <div className="share-popup-text">
              <div className='popup-logo'>
                <img 
                  src="https://d13dvi4jdbb8bg.cloudfront.net/MapIt-Logo-768x292px.png" 
                  width="118.36vh" 
                  height="45vh"
                  alt="MapIt logo"
                />
              </div> 
              <div className="popup-text">         
                <p className="p-live">Virtual Property Tour</p>
                <p className="p-share">Share Your</p>
                <p className="p-share">Interative Floor Plan</p>
              </div>
              <div className="popup-price">
                <p className="p-price">$9.99</p>
              </div>
              {/* <Link className="url-link-style" to="/preview" spy={true} smooth={true}>
                <MapItButton onSubmit={() => setPreview(true)} text="URL Preview" className="paypalbtn"/>
              </Link> */}
              <div className="popup-email">
                <div className="email-inner">
                <label className="email-label" htmlFor="email-address">Email</label>
                <input
                  className="email-input"
                  type="email"
                  name="email-address"
                  id="email-address"
                  placeholder="jack@realtygroup.com"
                  onChange={onEmailChange}
                />
                </div>
                <MapItButton onSubmit={onPayment} text="Continue" className="paypalbtn"/>
              </div>
            </div>
            <Loader.DotLoader
          color="#FCC81A"
          loading={loading}
          cssOverride={{position: "absolute", borderColor: "#FCC81A", marginLeft: "20vw", marginTop: "25vh"}}
          size={48}
          aria-label="Loading Spinner"
          data-testid="loader"
          />
          </div>
          <ToastContainer 
            position="bottom-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            theme="light"
          />
        </div>
      }
      <ToastContainer 
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="light"
      />
    </div>
  );
}

export default Create;