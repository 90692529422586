import React, {useState, useEffect} from 'react';
import "./Preview.css";

import MapItButton from '../../Common/MapItButton/MapItButton';
import { Link } from 'react-router-dom';

const Preview = ({floorplan, fpWidth, fpHeight, ssObjs, setPreview, snapSize}) => {
  const [selectedSS, setSelectedSS] = useState(0);
  const [ssViewActive, setSSViewActive] = useState(false);
  const [winX, setWinX] = useState(window.innerWidth);
  const [winY, setWinY] = useState(window.innerHeight);
  

  useEffect(() => {
    const handleResize = () => {
        setWinX(window.innerWidth);
        setWinY(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const imageClick = (ssId) => {
    setSelectedSS(ssId);
  }

  const getFpx = () => {
    return ((winX - (fpWidth*winX/0.8))/2);
  }

  const getFpy = () => {
    return ((winY - (fpHeight*winY/0.8))/2);
  }

  const onBackSS = () => {
    setSSViewActive(false);
    setSelectedSS(0);
  }

  const onBackFp = () => {
    setSSViewActive(false);
    setSelectedSS(0);
    setPreview(false);
  }

  const leftOrRight = (ssId) => {
    if (ssId === selectedSS && ((getFpx() + (ssObjs[ssId].coordinatex*fpWidth*winX/0.8) + ssObjs[ssId].width*winX*0.4)
    > (getFpx()+(fpWidth*winX/0.8)))) {
      return -1*ssObjs[ssId].width*winX*0.35;
    } else {
      return 0;
    }
  }

  const topOrBottom = (ssId) => {
    if (ssId === selectedSS && ((getFpy() + (ssObjs[ssId].coordinatey*fpHeight*winY/0.8) + ssObjs[ssId].height*winY*0.4)
    > (getFpy()+(fpHeight*winY/0.8)))) {
      return -1*ssObjs[ssId].height*winY*0.35;
    } else {
      return 0;
    }
  }

  const getSnapshotStyle = (ssId) => {
    if (ssObjs[ssId].coordinatex !== 0 || ssObjs[ssId].coordinatey !== 0) {
        console.log(`left: ${(getFpx()) + (ssObjs[ssId].coordinatex*fpWidth*winX/0.8)}`);
        console.log(`top: ${(getFpy()) + (ssObjs[ssId].coordinatey*fpHeight*winY/0.8)}`);
        return {position: 'absolute', 
        left: getFpx() + (ssObjs[ssId].coordinatex*fpWidth*winX/0.8) + leftOrRight(ssId), 
        top: getFpy() + (ssObjs[ssId].coordinatey*fpHeight*winY/0.8) + topOrBottom(ssId)
        };
      } else {
        return {};
      }
  }


  const getSSWidth = (ssId) => {
    let ssWidth;
    if (ssId === selectedSS) {
      ssWidth = ssObjs[ssId].width*winX*0.4;
    } else {
      ssWidth = fpWidth*winX*snapSize;
    }
    return ssWidth;
  }

  const getSSHeight = (ssId) => {
    let ssHeight;
    if (ssId === selectedSS) {
      ssHeight = ssObjs[ssId].height*winY*0.4;
    } else {
      ssHeight = fpWidth*winX*snapSize; // using width here is correct to make square icons
    }
    return ssHeight;
  }
  
  const onLargeImageClick = () => {
    setSelectedSS(0);
  }

  return (
    <div className="create">
      { floorplan && 
      <div className="edit-outter">
        <div className="snapshots">
        { !ssViewActive &&
          <>
          {Object.keys(ssObjs).map((ssId, index) => (
            <div className="ss-img">
              {ssId !== selectedSS
              ?
              <img 
              key={index} 
              src={ssObjs[ssId].local_file_path} 
              style={{...{border: "1.5px solid white", borderRadius: "5px", cursor: "pointer", width: "3vw", height: "3vw"}, 
              ...getSnapshotStyle(ssId)}} 
              onClick={() => imageClick(ssId)} 
              alt={`Uploaded content ${ssId}`} />
              :
              <img
              src={ssObjs[selectedSS].local_file_path} 
              style={{
                  ...{border: "1.5px solid white", zIndex:1, borderRadius: "5px", cursor: "pointer", 
                  width: getSSWidth(selectedSS), height: getSSHeight(selectedSS)}, 
                  ...getSnapshotStyle(selectedSS)}} 
              onClick={() => onLargeImageClick()}
              alt={`Uploaded content ${selectedSS}`} /> 
              }
            </div>          
          ))}
          </>
        }
        </div>
        <div className="floorplan">
          <img src={floorplan} width={fpWidth*winX/0.8} height={fpHeight*winY/0.8}/>
        </div>
        <div className="edit-btns">
            {!ssViewActive &&
                <div className="view-btn">
                    <Link className="Link-style" to="/create" spy={true} smooth={true}>
                        <MapItButton onSubmit={onBackFp} text="Back" className="viewbtn"/>
                    </Link>
                </div>
            }
            {ssViewActive &&
                <div className="view-btn">
                    <MapItButton onSubmit={onBackSS} text="Back" className="viewbtn"/>
                </div>
            }
        </div>
      </div>
      }
    </div>
  );
}

export default Preview;