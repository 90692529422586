import React, { useState } from 'react';
import './App.css';

import {BrowserView, MobileView, isMobileOnly, isAndroid, isIOS} from 'react-device-detect'

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Header from './Components/Desktop/Header/Header';
import Home from './Components/Desktop/Home/Home';
import ContactUs from './Components/Desktop/ContactUs/ContactUs';
import DownloadApp from './Components/Desktop/DownloadApp/DownloadApp';
import Features from './Components/Desktop/Features/Features';
import Industries from './Components/Desktop/Industries/Industries';
import Create from './Components/Desktop/Create/Create';
import Preview from './Components/Desktop/Preview/Preview';


import MHeader from './Components/Mobile/MHeader/MHeader';
import MFeatures from './Components/Mobile/MFeatures/MFeatures'
import MIndustries from './Components/Mobile/MIndustries/MIndustries';
import MContactUs from './Components/Mobile/MContactUs/MContactUs';
import MHome from './Components/Mobile/MHome/MHome';
import MDownloadApp from './Components/Mobile/MDownloadApp/MDownloadApp';
import MFooter from './Components/Mobile/MFooter/MFooter';
import MCreate from './Components/Mobile/MCreate/MCreate';

import Footer from './Components/Common/Footer/Footer';


const App = () => {

  const [create, setCreate] = useState(false);
  const [file, setFile] = useState();
  const [ssObjs, setSSObjs] = useState({});
  const [fpWidth, setFpWidth] = useState(0);
  const [fpHeight, setFpHeight] = useState(0);
  const [preview, setPreview] = useState(false);
  const [fpX, setFpx] = useState(0);
  const [fpY, setFpy] = useState(0);
  const [fpBase64, setFpBase64] = useState("");
  const [fpType, setFpType] = useState("");
  const [snapSize, setSnapSize] = useState(0.05);
  const [fpOgWidth,setFpOgWidth] = useState(0);
  const [fpOgHeight, setFpOgHeight] = useState(0);

  return (
    <>
      {!isMobileOnly
      ?
      <div className="App">
        <div className="Inner">
          <BrowserRouter>
          { !preview &&
          <Header create={create} setCreate={setCreate}></Header>
          }
          <div className="MapIt-body">
            <Routes>
              <Route exact path="/" element={<Home create={create} setCreate={setCreate}/>}></Route>
              <Route exact path="/features" element={<Features/>}></Route>
              <Route exact path="/industries" element={<Industries/>}></Route>
              <Route exact path="/download" element={<DownloadApp/>}></Route>
              <Route exact path="/contact" element={<ContactUs/>}></Route>
              <Route exact path="/create" element={
                <Create
                fpBase64={fpBase64}
                setFpBase64={setFpBase64}
                file={file}
                setFile={setFile} 
                ssObjs={ssObjs} 
                setSSObjs={setSSObjs} 
                fpWidth={fpWidth} 
                setFpWidth={setFpWidth}
                fpHeight={fpHeight} 
                setFpHeight={setFpHeight}
                setPreview={setPreview}
                fpX={fpX}
                fpY={fpY}
                setFpx={setFpx}
                setFpy={setFpy}
                fpType={fpType}
                setFpType={setFpType}
                snapSize={snapSize}
                setSnapSize={setSnapSize}
                fpOgHeight={fpOgHeight}
                fpOgWidth={fpOgWidth}
                setFpOgHeight={setFpOgHeight}
                setFpOgWidth={setFpOgWidth}/>}>
              </Route>
              <Route exact path='/preview' element={
                <Preview 
                floorplan={file} 
                fpHeight={fpHeight} 
                fpWidth={fpWidth} 
                ssObjs={ssObjs}
                setPreview={setPreview}
                snapSize={snapSize}/>}>
              </Route>
            </Routes>
          </div>
          </BrowserRouter>
          { !preview &&
          <Footer create={create}></Footer>
          }
        </div>
      </div>
      : // mobile browser website
      <div className="App">
        <div className="mInner">
          <BrowserRouter>
          <MHeader></MHeader>
          <div className="mMapIt-body">
            <Routes>
              <Route exact path="/" element={<MHome/>}></Route>
              <Route exact path="/features" element={<MFeatures/>}></Route>
              <Route exact path="/industries" element={<MIndustries/>}></Route>
              <Route exact path="/download" element={<MDownloadApp/>}></Route>
              <Route exact path="/contact" element={<MContactUs/>}></Route>
              <Route exact path="/create" element={<MCreate/>}></Route>
            </Routes>
          </div>
          </BrowserRouter>
          <MFooter></MFooter>
        </div>
      </div>
      }
    </>
  );
}

export default App;
